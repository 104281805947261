import { Row, Col, Container } from 'react-bootstrap'
import WorstSide from '../../assets/images/WorstSide.png'
import Logo from '../../assets/images/logo.png'
import LogoNew2 from '../../assets/images/LogoNew2.png'
import LogoNew from '../../assets/images/LogoNew.png'

import { Text } from "@chakra-ui/react";

function AboutPage () {
    return (
        <div className='container'>
            <div className='justify-content-center'>
                <Container style={{marginLeft: '3%', marginRight: '%'}}>
                <Row>
                    <Col lg="4" >
                        <Row>
                            <Col><img src={LogoNew2} width='171px' /></Col>
                        </Row>
                        <Row >
                            <Col className='text-end'><img style={{float:'right'}} src={WorstSide}  /></Col>
                        </Row>

                    </Col>
                    <Col lg="1"></Col>
                    <Col lg="6">
                        <div className='d-flex justify-content-end' >
                            <Text className={"font-v2"} fontSize='5xl'>About us</Text>
                            <img className='mt-3 mx-3' src={LogoNew} style={{width: '60px', height: '60px'}}  />
                        </div>
                        <label className={"font-v2"} style={{fontSize: '20px',width: '80%'}}>
                            Worst Ape was inspired by Bored Ape Yacht Club.
                            The appearance of Worst Ape fits the 1930s cartoon style perfectly.
                            However, the worst that is different at that time in the market was our main goal.
                        </label>
                    </Col>
                </Row>
                </Container>
            </div>
        </div>

    );
}

export default AboutPage;
