import {Row, Col} from 'react-bootstrap'
import Logo2 from '../../assets/images/NFTLOGO.png'
import {Text, Flex, Button, Stack, Box } from "@chakra-ui/react";
import LogoInstagram from '../../assets/images/icon-instragram.png'
import LogoFacebook from '../../assets/images/icon-facebook.png'
import LogoOpenseas from '../../assets/images/icon-opensea.png'
import LogoTwitter from '../../assets/images/icon-twitter.png'
import {BigNumber, ethers} from "ethers";
import WorstApeV2 from "../../WorstApeV2.json";
import axios from 'axios';
import {WorstApeContractV2} from "../../services/mutantFunctions";
import React from "react";

function FooterPage ({accounts, setAccounts,isMember,setMember,isMemberWorstApe,setMemberWorstApe}) {
    const isConnected =  Boolean(accounts[0]);
    let accountsAddress =  String(accounts).substring(0,6)+"..."+String(accounts).substring(38,42);
    async function getBalance(account) {
        if (window.ethereum) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                  params: [{ chainId: '0x1' }],
                });


                if (window.ethereum) {
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    const signer = provider.getSigner();
                    const contract = new ethers.Contract(
                      WorstApeContractV2,
                        WorstApeV2.abi,
                        signer
                    );
                   const wallet = account[0];
                    try {
                        const _checkBalance  = await contract.balanceOf(wallet);
                        const cost = Number(_checkBalance.toString());
                        console.log(cost)
                        if(parseInt(cost) > 0) {
                            isMember = true;
                            setMember(true);
                        }
                        return true;

                    } catch (err) {
                        console.log("error1: ", err);
                    }
                }


            } catch (e) {

            }
        }

    }
    async function getBalanceWA(account) {
        const wallet = account[0];
        const options = {
            method: 'GET',
            url: `https://deep-index.moralis.io/api/v2/${wallet}/nft`,
            params: {chain: 'eth', format: 'decimal'},
            headers: {accept: 'application/json', 'X-API-Key': 's0iwyeW3C6QEFDIRBT5JuwSgL3x5jrbqHQlA9eWtRUaODHtdRU8vQKEkKosWAMTn'}
        };
        axios
            .request(options)
            .then(async function (response) {
                const wa = await response.data.result.find((data) => {
                    return data.token_id.includes("9224431407541258791222053918714298690910471583409604551328487");
                });
                if (wa==null){
                    console.log('not worst ape')
                }else{
                    console.log('worst ape')

                    isMemberWorstApe = true;
                    setMemberWorstApe(true);
                }
                // for (let i = 0; i < openSeaNft; i++) {
                //
                //     if (openSeaNft[i]['token_address'] === "0x495f947276749ce646f68ac8c248420045cb7b5e") {
                //            // if (openSeaNft[i]['token_id'].includes("9224431407541258791222053918714298690910471583409604551328487") == true) {
                //                console.log(openSeaNft[i]['token_id']);
                //
                //            // }
                //        }
                //    }
            })
            .catch(function (error) {
                console.error(error);
            });

    }
    async function connectAccount() {
        if (window.ethereum) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                  params: [{ chainId: '0x1' }],
                });
                if (window.ethereum) {
                    const account = await window.ethereum.request({
                        method: "eth_requestAccounts"
                    });
                    getBalance(account);
                    getBalanceWA(account);
                    setAccounts(account);
                    console.log(account);


                }

            } catch (e) {

            }
        }

    }
    return (
        <div className='container-fluid'>
               <Row>
                <Col lg={3} className='py-5 d-flex'>
                  <a href="https://nftcalendar.io/"><img className='mx-5 my-5' src={Logo2} width="150px"   /></a>
                    <div className="vr" style={{color: '#333333'}}></div>
                  {/*<a className='mx-5 my-5' href="https://nftcalendar.io/"  width="150px" color={"red"}>*/}
                  {/*  <Button ><img  src={Logo2} color={"red"}  /></Button>*/}
                  {/*</a>*/}
                </Col>
                <Col lg={4} className='py-5 text-start'>
                    {/*<div className='d-flex align-items-start flex-column'>*/}
                    {/*    <Text className='font-v2' fontSize={{ base: '18px', md: '28px', lg: '24px' }}>About</Text>*/}
                    {/*    <Text className='font-v2' fontSize={{ base: '18px', md: '28px', lg: '24px' }}>RoadMap</Text>*/}
                    {/*    <Text className='font-v2' fontSize={{ base: '18px', md: '28px', lg: '24px' }}>Teams</Text>*/}
                    {/*    <Text className='font-v2' fontSize={{ base: '18px', md: '28px', lg: '24px' }}>Collections</Text>*/}
                    {/*</div>*/}
                </Col>
                <Col lg={5} className='py-5'>
                    <div className='font-v2'>
                        <Text fontSize={{ base: '18px', md: '28px', lg: '32px' }}>Join Newsletter</Text>
                        <div className='mt-3' style={{width: '80%'}}>
                        <Text fontSize={{ base: '18px', md: '28px', lg: '24px' }}>Subscribe to our newsletter to get the latest news and updates</Text>
                        </div>
                    </div>
                    <Flex display={{ base: 'none', md: 'flex' }} gap="3" mr={7}>
                    {/* <Button className='btn-circle'><img src={LogoInstagram} /></Button>
                    <Button className='btn-circle'><img src={LogoFacebook} /></Button> */}
                    <a target='_blank' href="https://twitter.com/Worst_Ape?fbclid=IwAR0Y90kuMJoDuJUMuzX8g-r9Fln1bXF2euzGMrLMZrytv-CJiaNZEGfxw5w">
                      <Button className='btn-circle'><img src={LogoTwitter} /></Button>
                    </a>
                    <a target='_blank' href="https://opensea.io/collection/worst-mutant-ape">
                    <Button className='btn-circle'><img src={LogoOpenseas} /></Button>
                    </a>
                    <Stack
                        flex={{ base: 1, md: 0 }}
                        justify={'flex-end'}
                        direction={'row'}
                        spacing={6}>
                    //          {/* Connect */}
                        {isConnected ? (<Box margin="0 15px"><Text className={"font-v2-address"} color="#FFFFFF">{accountsAddress}</Text></Box>) :<Button
                          className="btn-link"
                          fontFamily="Inter"
                          fontSize={"20px"}
                          fontWeight="bold"
                          textDecoration="none"
                          color={'#FFFFFF'}
                          variant='outline'
                          colorScheme='#FFFFFF'
                          href={'#'}
                          _hover={{
                            bg: '#C69361',
                            color: '#FFF',
                            border: '#C69361'
                          }}

                          onClick={connectAccount}>
                          Connect wallet
                        </Button>}
                    </Stack>
                    </Flex>
                </Col>
               </Row>
        </div>

    );
}

export default FooterPage;
