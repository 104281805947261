import { Button, Spacer, Text } from "@chakra-ui/react";
import { Row, Col, Container } from "react-bootstrap";
import ImgMutant1 from "../../assets/images/mutant1.png";
import ImgMutant2 from "../../assets/images/mutant2.png";
import ImgMutant3 from "../../assets/images/mutant3.png";
import TitleMutant from "../../assets/images/titleMutants.png";
import {connectAccount, contractV2} from "../layout/navbar_head";
import {BigNumber, ethers} from "ethers";
import MySwal from "sweetalert2";
import { useNavigate  } from "react-router-dom";
import MintDetails from "../../assets/images/mintdetail.png";


function MutantComponent() {
  const navigate = useNavigate();
  //   async function goMutant() {
  //
  //   if (contractV2) {
  //    ///  เปลี่ยน path
  //    navigate("/mutants");
  //    this.history.push('/mutants')
  //   } else {
  //     await MySwal.fire({
  //       html: 'Please Connet Wallet',
  //       icon: 'error',
  //       type: "error",
  //       iconColor: 'black',
  //       confirmButtonColor: 'black'
  //     });
  //   }
  // }
  return (
    <div>
      <Container>
        {/*<div className="d-flex py-2">*/}
        {/*<Text fontSize='2xl' className="text-start">Event For   </Text>*/}
        {/*<Text>&nbsp;&nbsp;</Text>*/}
        {/*<Text fontSize='2xl' color="#DCB839">Week</Text>*/}
        {/*</div>*/}

        <img className="mx-auto d-block" src={MintDetails}/>

        <div id="team" style={{marginTop: "50px"}}>
        </div>

        <img className="mx-auto d-block" src={TitleMutant}/>


        {/*<Button onClick={goMutant} className="btn-mutant my-5" color='white' backgroundColor="#DCB839">Mutant Now</Button>*/}
      </Container>
    </div>
  );
}

export default MutantComponent;
