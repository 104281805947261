import React from 'react';
import {
    Box,
    Button,
    Flex,
    Image, Link,
    Spacer, Text,
    Stack,
    Collapse,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Icon,
    IconButton,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure
    } from '@chakra-ui/react'
import {
        HamburgerIcon,
        CloseIcon,
        ChevronDownIcon,
        ChevronRightIcon,
      } from '@chakra-ui/icons';
import {BigNumber, ethers} from "ethers";
import WorstApeV2 from "../../WorstApeV2.json";
import axios from 'axios';

import LogoOpenseas from '../../assets/images/icon-opensea.png'
import LogoTwitter from '../../assets/images/icon-twitter.png'
import Logo from '../../assets/images/LogoNew.png'
import { Container } from 'react-bootstrap';
import {WorstApeContractV2} from "../../services/mutantFunctions";




export let wallet_Address = '0x'
export let contractV2 ;
 const NavBar = ({accounts, setAccounts,isMember,setMember,isMemberWorstApe,setMemberWorstApe}) => {
    const isConnected =  Boolean(accounts[0]);
    let accountsAddress =  String(accounts).substring(0,6)+"..."+String(accounts).substring(38,42);
     async function connectAccount() {
         if (window.ethereum) {
             try {
                 await window.ethereum.request({
                     method: 'wallet_switchEthereumChain',
                   params: [{ chainId: '0x1' }],
                 });
                 const provider = new ethers.providers.Web3Provider(window.ethereum);
                 const signer = provider.getSigner();
                 contractV2 = new ethers.Contract(
                   WorstApeContractV2,
                     WorstApeV2.abi,
                     signer
                 );


                 if (window.ethereum) {
                     const account = await window.ethereum.request({
                         method: "eth_requestAccounts"
                     });
                     setAccounts(account);
                     wallet_Address = account[0];


                 }

             } catch (e) {

             }
         }

     }
     // async function getBalance(account) {
     //     if (window.ethereum) {
     //         try {
     //             await window.ethereum.request({
     //                 method: 'wallet_switchEthereumChain',
     //               params: [{ chainId: '0x1' }],
     //             });
     //
     //
     //             if (window.ethereum) {
     //                 const provider = new ethers.providers.Web3Provider(window.ethereum);
     //                 const signer = provider.getSigner();
     //                 const contract = new ethers.Contract(
     //                   WorstApeContractV2,
     //                     WorstApeV2.abi,
     //                     signer
     //                 );
     //                const wallet = account[0];
     //                 try {
     //                     const _checkBalance  = await contract.balanceOf(wallet);
     //                     const cost = Number(_checkBalance.toString());
     //                     console.log(cost)
     //                     if(parseInt(cost) > 0) {
     //                         isMember = true;
     //                         setMember(true);
     //                     }
     //                     return true;
     //
     //                 } catch (err) {
     //                     console.log("error1: ", err);
     //                 }
     //             }
     //
     //
     //         } catch (e) {
     //
     //         }
     //     }
     //
     // }
     // async function getBalanceWA(account) {
     //     const wallet = account[0];
     //     const options = {
     //         method: 'GET',
     //         url: `https://deep-index.moralis.io/api/v2/${wallet}/nft`,
     //         params: {chain: 'eth', format: 'decimal'},
     //         headers: {accept: 'application/json', 'X-API-Key': 's0iwyeW3C6QEFDIRBT5JuwSgL3x5jrbqHQlA9eWtRUaODHtdRU8vQKEkKosWAMTn'}
     //     };
     //     axios
     //         .request(options)
     //         .then(async function (response) {
     //             const wa = await response.data.result.find((data) => {
     //                 return data.token_id.includes("9224431407541258791222053918714298690910471583409604551328487");
     //             });
     //             if (wa==null){
     //                 console.log('not worst ape')
     //             }else{
     //                 console.log('worst ape')
     //
     //                 isMemberWorstApe = true;
     //                 setMemberWorstApe(true);
     //             }
     //             // for (let i = 0; i < openSeaNft; i++) {
     //             //
     //             //     if (openSeaNft[i]['token_address'] === "0x495f947276749ce646f68ac8c248420045cb7b5e") {
     //             //            // if (openSeaNft[i]['token_id'].includes("9224431407541258791222053918714298690910471583409604551328487") == true) {
     //             //                console.log(openSeaNft[i]['token_id']);
     //             //
     //             //            // }
     //             //        }
     //             //    }
     //         })
     //         .catch(function (error) {
     //             console.error(error);
     //         });
     //
     // }
     const NAV_ITEMS = [

    // {
    //   label: 'Twitter',
    //   href: 'https://twitter.com/Worst_Ape?fbclid=IwAR0Y90kuMJoDuJUMuzX8g-r9Fln1bXF2euzGMrLMZrytv-CJiaNZEGfxw5w',
    // },
    // {
    //   label: 'Opensea',
    //   href: 'https://opensea.io/collection/worst-side?fbclid=IwAR1w2GJFDKio_qEOWIA8iBsD2z9WvwXIgpgYeb8ZvVhDF1ZeeWaUSwUZDTs',
    // },
    {
        label: 'About',
        href: '#about',
    },
    {
        label: 'RoadMap',
        href: '#roadMap',
    },
    {
        label: 'Team',
        href: '#team',
    },
    // {
    //     label: 'Collections',
    //     href: '#collections',
    // },
  ];

  const DesktopNav = () => {
    const linkColor = useColorModeValue('white', 'white.200');
    const linkHoverColor = useColorModeValue('#FBC101', 'white');

    return (
      <Stack direction={'row'} spacing={4} className="mt-2">
        {NAV_ITEMS.map((navItem) => (
          <Box key={navItem.label}>
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <PopoverTrigger>
                <Link
                  p={2}
                  href={navItem.href ?? '#'}
                  fontFamily="Inter"
                  fontSize={"25px"} fontWeight="bold"
                  color={linkColor}
                  _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                  }}>
                  {navItem.label}
                </Link>
              </PopoverTrigger>
            </Popover>
          </Box>
        ))}
      </Stack>
    );
  };

  const MobileNav = () => {
    return (
      <Stack
        bg={useColorModeValue('white', 'gray.800')}
        p={4}
        display={{ md: 'none' }}>
        {NAV_ITEMS.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
      </Stack>
    );
  };

  const MobileNavItem = ({ label, children, href }) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
      <Stack spacing={4} onClick={children && onToggle}>
        <Flex
          py={2}
          as={Link}
          href={href ?? '#'}
          justify={'space-between'}
          align={'center'}
          _hover={{
            textDecoration: 'none',
          }}>
          <Text
            fontWeight={600}
            color={useColorModeValue('gray.600', 'gray.200')}>
            {label}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Flex>
      </Stack>
    );
  };

  const { isOpen, onToggle } = useDisclosure();
     return (
        <Box marginLeft={'10%'} marginRight={'10%'}>
          <Container fluid>
          <Flex
             minH={'60px'}
             py={{ base: 2 }}
             align={'center'}>
                <Flex
                flex={{ base: 1, md: 'auto' }}
                ml={{ base: -2 }}
                display={{ base: 'flex', md: 'none' }}>
                <IconButton
                    onClick={onToggle}
                    icon={
                    isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                    }
                    variant={'ghost'}
                    aria-label={'Toggle Navigation'}
                /></Flex>
                <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>

                    <Text
                        textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
                        fontFamily={'heading'}
                        color={useColorModeValue('gray.800', 'white')}>

                    </Text>

                    <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                        <Link href='http://localhost:3000/'><img  src={Logo} width='65%'  /></Link>
                        <DesktopNav />
                    </Flex>
                </Flex>
                <Flex display={{ base: 'none', md: 'flex' }} gap="3" mr={7}>
                    {/* <Button className='btn-circle'><img src={LogoInstagram} /></Button>
                    <Button className='btn-circle'><img src={LogoFacebook} /></Button> */}
                     <a target='_blank' href="https://twitter.com/Worst_Ape?fbclid=IwAR0Y90kuMJoDuJUMuzX8g-r9Fln1bXF2euzGMrLMZrytv-CJiaNZEGfxw5w">
                      <Button className='btn-circle'><img src={LogoTwitter} /></Button>
                     </a>
                     <a target='_blank' href="https://opensea.io/collection/worst-mutant-ape">
                      <Button className='btn-circle'><img src={LogoOpenseas} /></Button>
                     </a>

                </Flex>
                <Stack
                    flex={{ base: 1, md: 0 }}
                    justify={'flex-end'}
                    direction={'row'}
                    spacing={6}>
                //          {/* Connect */}
                    {isConnected ? (<Box margin="0 15px"><Text className={"font-v2-address"} color="#FFFFFF">{accountsAddress}</Text></Box>) :<Button
                      className="btn-link"
                      fontFamily="Inter"
                      fontSize={"20px"}
                      fontWeight="bold"
                      textDecoration="none"
                      color={'#FFFFFF'}
                      variant='outline'
                      colorScheme='#FFFFFF'
                      href={'#'}
                      _hover={{
                        bg: '#C69361',
                        color: '#FFF',
                        border: '#C69361'
                      }}

                      onClick={connectAccount}>
                      Connect wallet
                    </Button>}
                </Stack>
            </Flex>
            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>
          </Container>
        </Box>
        //  <Flex justify="space-evenly" align="center" align="right" padding="30px">

        //  {/* Left Side - Social Media Icons */}
        //      <Flex justify="space-evenly" align={'center'}  width="40%" padding="0 75px" fontSize={"20px"} fontWeight="medium">
        //          <div>Twitter</div>
        //          <div>Opensea</div>
        //  </Flex>

        //      <Flex justify="space-evenly" align={'center'} width="40%" padding="0 30px" fontSize={"20px"} fontWeight="medium">

        //      {/* Right Side - Sections and Conect */}
        //          <div>RoadMap</div>
        //      <div>About</div>
        //      <div>Team</div>

        //      </Flex>

        //  </Flex>

    )
}

export default NavBar;

export async function connectAccount() {
    if (window.ethereum) {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x1' }],
            });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            contractV2 = new ethers.Contract(
                WorstApeContractV2,
                WorstApeV2.abi,
                signer
            );


            if (window.ethereum) {
                const account = await window.ethereum.request({
                    method: "eth_requestAccounts"
                });
                wallet_Address = account[0];

            }

        } catch (e) {

        }
    }

}
