import "./App.css";
import "./assets/custom.css"
import MainMint from "./MainMint";
import NavbarHeader from "./components/layout/navbar_head";
import Footer from "./components/layout/footer";
import CarouselPage from "./components/pages/CarouselPage";
import AboutPage from "./components/pages/AboutPage";
import OurTeamPage from "./components/pages/OurTeamPage";
import OurProjectPage from "./components/pages/OurProjectsPage";
import CountdownTimer from "./CountdownTimer";
import MutantComponent from "./components/pages/MutantComponent";


import { useState } from "react";
import RoadMap from "./RoadMap";
import About from "./About";
import { Box, Flex, Text } from "@chakra-ui/react";
import Team from "./Team";
import MintPage from "./components/pages/MintPage";
import CollectionList from "./components/pages/CollectionsList";
import QAPage from "./components/pages/QAPage";


function Main() {
  const [accounts, setAccounts] = useState([]);
  const [isMember, setMember] = useState(false);
  const [isMemberWorstApe, setMemberWorstApe] = useState(false);
  const isConnected = Boolean(accounts[0]);
  // const NOW_IN_MS = new Date().getTime();
  // const THREE_DAYS_IN_MS = 2 * 24 * 60 * 60 * 1000;

  const dateTimeAfterThreeDays = 1689206400000;
  console.log(dateTimeAfterThreeDays)
  return (
    <div>
      <div className="overlay">
        <div className="App">

        <div id="home" style={{marginTop: "110px"}}>
          <CountdownTimer targetDate={dateTimeAfterThreeDays}/>
        </div>
        <div style={{marginTop: "75px"}}>
          <MintPage/>
        </div>
        <div className="py-5" style={{marginTop: "10px"}}>
          <MutantComponent />

        </div>
          {/*<div style={{marginTop: "0px"}}>*/}
          {/*  <CarouselPage />*/}
          {/*</div>*/}
          <div id="roadMap" style={{marginTop: "80px"}}>
            <CarouselPage />
          </div>
        <div id="about" style={{marginTop: "80px"}}>
          <AboutPage />
        </div>
        <div id="team" style={{marginTop: "64px"}}>
          <OurTeamPage />
        </div>

          {/* <MainMint
            accounts={accounts}
            setAccounts={setAccounts}
            isMember={isMember}
            setMember={setMember}
            isMemberWorstApe={isMemberWorstApe}
            setMemberWorstApe={setMemberWorstApe}
          /> */}
        </div>

      </div>

    </div>
  );
}
export default Main;
