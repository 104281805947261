import "./App.css";
import "./assets/custom.css"
import React, { Component, useState } from 'react';
import { Routes, Route, Link } from "react-router-dom";
import NavbarHeader from './components/layout/navbar_head'
import Footer from './components/layout/footer'
import Main from './Main'
import CollectionAll from './components/pages/CollectionAll'
import Muatants from './components/pages/MutantPage'




function App() {
  const [accounts, setAccounts] = useState([]);
  const [isMember, setMember] = useState(false);
  const [isMemberWorstApe, setMemberWorstApe] = useState(false);
  const isConnected = Boolean(accounts[0]);
  return (
    <div style={{width: '100%'}}>
       <NavbarHeader 
        accounts={accounts}
        setAccounts={setAccounts}
        isMember={isMember}
        setMember={setMember}
        isMemberWorstApe={isMemberWorstApe}
        setMemberWorstApe={setMemberWorstApe}
       />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/collections/:type" element={<CollectionAll />} />
        <Route path="/mutants" element={<Muatants />} />

      </Routes>
      <div className="footer">
          <Footer accounts={accounts}
          setAccounts={setAccounts}  />
      </div>
      <div className="moving-background" />
    </div>
  );
}
export default App;
