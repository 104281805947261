import {
  Text,
  Grid,
  GridItem,
  Spacer,
  SimpleGrid,
  Box,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

import CardWorst from "../layout/CardWorst";
import { Container } from "react-bootstrap";

import IconWC from "../../assets/images/iconWC.png";
import ImgWC1 from "../../assets/images/worstCity1.png";

import IconWS from "../../assets/images/IconWS.png";
import ImgWS1 from "../../assets/images/ImgWS1.png";

function CollectionAll() {
  var cardWCList = [];
  var cardWSList = [];

  var objWorstCity = [
    {
      logo: IconWC,
      title: "Worst City",
      Img: ImgWC1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWC,
      title: "Worst City",
      Img: ImgWC1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWC,
      title: "Worst City",
      Img: ImgWC1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWC,
      title: "Worst City",
      Img: ImgWC1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWC,
      title: "Worst City",
      Img: ImgWC1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWC,
      title: "Worst City",
      Img: ImgWC1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWC,
      title: "Worst City",
      Img: ImgWC1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWC,
      title: "Worst City",
      Img: ImgWC1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWC,
      title: "Worst City",
      Img: ImgWC1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
  ];
  var objWorstSide = [
    {
      logo: IconWS,
      title: "Worst Side",
      Img: ImgWS1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWS,
      title: "Worst Side",
      Img: ImgWS1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWS,
      title: "Worst Side",
      Img: ImgWS1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWS,
      title: "Worst Side",
      Img: ImgWS1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWS,
      title: "Worst Side",
      Img: ImgWS1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWS,
      title: "Worst Side",
      Img: ImgWS1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWS,
      title: "Worst Side",
      Img: ImgWS1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWS,
      title: "Worst Side",
      Img: ImgWS1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWS,
      title: "Worst Side",
      Img: ImgWS1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
    {
      logo: IconWS,
      title: "Worst Side",
      Img: ImgWS1,
      tagId: "414",
      lastSale: 1.5,
      price: 1.5,
    },
  ];
  for (let i = 0; i < objWorstCity.length; i++) {
    cardWCList.push(
      <Box w="95%">
        <CardWorst dataImg={objWorstCity} />
      </Box>
    );
  }

  for (let i = 0; i < objWorstSide.length; i++) {
    cardWSList.push(
      <Box w="95%">
        <CardWorst dataImg={objWorstSide} />
      </Box>
    );
  }

  return (
    <div>
      <Container>
        <div className="d-flex align-items-center my-5">
          <Text color={"white"} fontSize="5xl">
            Explorer The Best NFT Assets
          </Text>
        </div>
        <Tabs variant='unstyled' >
          <TabList>
            <Tab style={{border: "1px solid #DCB839", borderRadius: "16px"}}
            _selected={{ color: "white", bg: "yellow.400" }}>
            Worst City</Tab>
            <Tab className="mx-3" style={{border: "1px solid #DCB839", borderRadius: "16px"}}
            _selected={{ color: "white", bg: "yellow.400" }}>
            Worst Side</Tab>
            <Tab style={{border: "1px solid #DCB839", borderRadius: "16px"}}
            _selected={{ color: "white", bg: "yellow.400"}}>Worst Ape</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }}>
                {cardWCList}
              </SimpleGrid>
            </TabPanel>
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }}>
                {cardWSList}
              </SimpleGrid>
            </TabPanel>
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }}>
                {cardWCList}
              </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </div>
  );
}

export default CollectionAll;
