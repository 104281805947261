import {Row, Col, Card, Container } from 'react-bootstrap'
import Logo from '../../assets/images/logo.png'
import Person1 from '../../assets/images/person1.png'
import Person2 from '../../assets/images/person2.png'
import Pic1 from "../../assets/images/pic1.png";
import Pic2 from "../../assets/images/pic2.png";
import Collapse1 from '../../assets/images/collapse1.png'
import Collapse2 from '../../assets/images/collapse2.png'

import {Text } from "@chakra-ui/react";

function OurTeamPage () {
    return (
        <div className='container'>
                <div className='d-flex justify-content-center'>
                    <Text className={"font-v2"} fontSize={{ base: '56px', md: '72px', lg: '6xl' }}>Our Team</Text>
                    {/*<img className='mt-3 mx-3' src={Logo} style={{width: '60px', height: '60px'}}  />*/}
                </div>
                <div className='d-flex justify-content-center'>
                    <Card bg="dark" className='hightlight-border-team' text='white'>
                        <Card.Img variant="top" src={Person1} style={{padding: '5%'}} />
                        <Card.Body>
                            <Card.Img  variant="top" src={Pic1} style={{padding: '30%', position: 'absolute', top: '26%', left:0}} />
                            <Card.Text className="mt-3">
                               <label className='mb-3'>Worst Person</label>
                               <p>Troublemaker</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className='hightlight-border-team' bg="dark" text='white'>
                        <Card.Img variant="top" src={Person2} style={{padding: '5%'}} />
                        <Card.Body>
                            <Card.Img  variant="top" src={Pic2} style={{padding: '30%', position: 'absolute', top: '26%', left:0}} />
                            <Card.Text className="mt-3 text-center">
                               <label className='mb-3'>MAZE</label>
                               <p>Digital Overlord</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                {/*<div style={{marginTop: '124px'}}>*/}
                {/*        <Row>*/}
                {/*            <Col xs={12} md={6} lg={6}>*/}
                {/*                <img src={Collapse1} style={{width: '80%', float:'right'}}  />*/}
                {/*            </Col>*/}
                {/*            <Col xs={12} md={6} lg={6} className='text-start d-flex align-items-start flex-column'>*/}
                {/*                <div className='mb-auto'>*/}
                {/*                    <Text fontSize={{base: '30px', md: '5xl', lg:'5xl'}}>Story about “Artist”</Text>*/}
                {/*                    <div>*/}
                {/*                        <Text fontSize={{base: '30px', md: '5xl', lg:'5xl'}}>Fire Gallery</Text>*/}
                {/*                    </div>*/}
                {/*                    <hr width='73%' />*/}
                {/*                    <Text fontSize={{base: '14px', md: 'md', lg:'lg'}} width='73%'>*/}
                {/*                    The proprietor of Fire Gallery is Chaiwath Siriwong. He has 18 years of experience in a variety of industries and is a gifted artist. Chaiwath is a southern Thai native who has always loved to make art. Our work together is but a small portion of his journey.*/}
                {/*                    </Text>*/}
                {/*                </div>*/}
                {/*                <div>*/}
                {/*                    <Text fontSize='4xl'>Visit Fire Gallery</Text>*/}
                {/*                    <Text fontSize='lg'>*/}
                {/*                    (https://www.facebook.com/firefire23)*/}
                {/*                    </Text>*/}
                {/*                </div>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*</div>*/}
                {/*<div style={{marginTop: '32px'}}>*/}
                {/*    <Container >*/}
                {/*        <Card bg="dark" style={{width: '88%', marginLeft: 'auto', marginRight: 'auto'}}>*/}
                {/*            <Card.Img src={Collapse2} />*/}
                {/*        </Card>*/}
                {/*    </Container>*/}
                {/*</div>*/}
        </div>

    );
}

export default OurTeamPage;
