import {
  Text,
  SimpleGrid,
  Box,
  Button,
  Container,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spinner,
  useDisclosure,
  useControllableState,

} from "@chakra-ui/react";
import IconETH from "../../assets/images/iconEth.png";
import IconUser from "../../assets/images/person1.png";
import TitleMutant from "../../assets/images/titleMutants.png";
import { CardWMutant } from "../layout/CardWorst";

import IconMutant from '../../assets/images/mutant.png'
import ImgIconMutant from '../../assets/images/mutant.png'

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {eatPhilandApe, getAPE, getPhileandSide} from "../../services/mutantFunctions";
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay';
import {contractV2, wallet_Address} from "../layout/navbar_head";
import {BigNumber, ethers} from "ethers";
import MySwal from "sweetalert2";


var objWorstCity = [
  {
    id: 1,
    logo: IconMutant,
    title: "Worst City",
    Img: ImgIconMutant,
    tagId: "409",
    lastSale: 1.5,
    price: 1.5,
  },
  {
    id: 2,
    logo: IconMutant,
    title: "Worst City",
    Img: ImgIconMutant,
    tagId: "410",
    lastSale: 1.5,
    price: 1.5,
  },
  {
    id: 3,
    logo: IconMutant,
    title: "Worst City",
    Img: ImgIconMutant,
    tagId: "411",
    lastSale: 1.5,
    price: 1.5,
  },
  {
    id: 4,
    logo: IconMutant,
    title: "Worst City",
    Img: ImgIconMutant,
    tagId: "412",
    lastSale: 1.5,
    price: 1.5,
  },
  {
    id: 5,
    logo: IconMutant,
    title: "Worst City",
    Img: ImgIconMutant,
    tagId: "413",
    lastSale: 1.5,
    price: 1.5,
  },
  {
    id: 6,
    logo: IconMutant,
    title: "Worst City",
    Img: ImgIconMutant,
    tagId: "414",
    lastSale: 1.5,
    price: 1.5,
  },
  {
    id: 7,
    logo: IconMutant,
    title: "Worst City",
    Img: ImgIconMutant,
    tagId: "415",
    lastSale: 1.5,
    price: 1.5,
  },
  {
    id: 8,
    logo: IconMutant,
    title: "Worst City",
    Img: ImgIconMutant,
    tagId: "416",
    lastSale: 1.5,
    price: 1.5,
  },
];
var resultCard = [
    {
        id: 8,
        logo: IconMutant,
        title: "Worst City",
        Img: ImgIconMutant,
        tagId: "416",
        lastSale: 1.5,
        price: 1.5,
      },
]
var defaultCard = [
  {
    id: null,
    logo: IconMutant,
    title: "---",
    Img: ImgIconMutant,
    tagId: "---",
    lastSale: 1.5,
    price: 1.5,
    isDefault: true,
  },
  // {
  //   id: null,
  //   logo: IconMutant,
  //   title: "---",
  //   Img: ImgIconMutant,
  //   tagId: "---",
  //   lastSale: 1.5,
  //   price: 1.5,
  //   isDefault: true,
  // },
];
function isBlur(selected, item) {
  let noBlur = selected.filter((res) => {
    return res.id == item.id;
  });
  let noSelect = selected.filter((response) => {
    return response.id != null;
  });
  return noSelect.length >= 2 && noBlur.length <= 0;
}

function Mutant() {
  const [selectCard, setSelectCard] = useState(defaultCard);
  const [list, setList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false)
  let connect = false;
    // const [totalNFT, settotalNFT] = useState(0);
  const isDisabled = useMemo(() => {
    return (
      selectCard.filter((response) => {
        console.log(response.id != null);
        return response.id != null;
      }).length >= 1
    );
  }, [selectCard]);
    // const nft = useMemo(() => getNFT(), [totalNFT]);
    // const getNFT = async () => {
    //
    //     return await contractV2._lv1();
    //
    // };

    const [message, setMessage] = useState('');


    // setInterval(async () => {
    //     const totalMint = await contractV2._mutant();
    //     const total = totalMint - 4455;
    //     setMessage(`Mutant: ${total}/1100`);
    //     // setMessage(`4455`);
    //
    // }, 1000);
  useEffect(() => {
    setList([]);
      getPhileandSide().then(res => {
        if (res.length > 0) {
          res.map((val, i) => {
            console.log(val.token_id)
            const dataParse = JSON.parse(val.metadata)
            const data = {
              id: i + 1,
              logo: IconMutant,
              title: "---",
              Img: dataParse.image,
              tagId: dataParse.name,
              tokenId: val.token_id,
              lastSale: 0,
              price: 0,
            }
            setList(arrayList => [...arrayList, data])
          })
          connect = true;
        }
      }).catch(e => {
        console.log(e)
      })

  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      console.log(connect, 'connect');
      if(connect==false) {
        setList([]);
        getPhileandSide().then(res => {
          if (res.length > 0) {
            res.map((val, i) => {
              console.log(val.token_id)
              const dataParse = JSON.parse(val.metadata)
              const data = {
                id: i + 1,
                logo: IconMutant,
                title: "---",
                Img: dataParse.image,
                tagId: dataParse.name,
                tokenId: val.token_id,
                lastSale: 0,
                price: 0,
              }
              setList(arrayList => [...arrayList, data])
            })
            connect = true;
          }
        }).catch(e => {
          console.log(e)
        })
      }
      }, 5000);
      return () => clearInterval(interval);
  }, []);



  // const listItems =

  // const listCardMutant =
  console.log(selectCard);
  const handleClick = useCallback(
    (value) => {
      console.log(value);
      console.log(selectCard);
      var filterDefault = selectCard.findIndex((val) => {
        if (val.id == null) {
            console.log(value.id != selectCard[0].id)
            if (value.id != selectCard[0].id) {
                return val.isDefault === true;
            } else {
                return
            }
        }
      });
      console.log(filterDefault);
      if (filterDefault >= 0) {
        let data = [...selectCard];
        data[filterDefault] = selectCard[filterDefault] = value;
        console.log(data);
        setSelectCard(data);
      }
    },
    [selectCard]
  );

  const handleRemove = useCallback(
    (value) => {
      console.log(value);
      if (value.id) {
        const getId = selectCard.filter((i) => {
          return i.id != value.id;
        });
        getId.push({
          id: null,
          logo: IconMutant,
          title: "---",
          Img: ImgIconMutant,
          tagId: "---",
          lastSale: 1.5,
          price: 1.5,
          isDefault: true,
        });
        console.log(getId);
        setSelectCard(getId);

        console.log(selectCard);
      }
    },
    [selectCard]
  );

  // const goMutant = useCallback(async (value) => {
      // setIsLoading(true)
      // setTimeout(function () {
      //     onOpen()
      //     setIsLoading(false)
      // },3000)
      //
    async function goMutant(pill) {
        if (contractV2) {
          const ape = await getAPE();
          console.log('APE'+ape.length);
          if(ape.length == 0){
            await MySwal.fire({
              html: 'Only Ape Owner can Take Pill',
              icon: 'error',
              type: "error",
              iconColor: 'black',
              confirmButtonColor: 'black'
            });

          }else {
            try {

              const eat = await eatPhilandApe(pill[0].tokenId);
              if (eat == true) {
                setList([]);
                getPhileandSide().then(res => {
                  if (res.length > 0) {
                    res.map((val, i) => {
                      console.log(val.token_id)
                      const dataParse = JSON.parse(val.metadata)
                      const data = {
                        id: i + 1,
                        logo: IconMutant,
                        title: "---",
                        Img: dataParse.image,
                        tagId: dataParse.name,
                        tokenId: val.token_id,
                        lastSale: 0,
                        price: 0,
                      }
                      setList(arrayList => [...arrayList, data])
                    })
                    console.log(list)
                  }
                }).catch(e => {
                  console.log(e)
                })
                // }
              }
              //
              // console.log("response: ", response.data);
              // }
            } catch (err) {

              await MySwal.fire({
                html: err.reason,
                icon: 'error',
                type: "error",
                iconColor: 'black',
                confirmButtonColor: 'black'
              });
            }
          }
        }
    }

      return (
          <>

              <div className="container text-center text-white">
                  <img className="mx-auto d-block img-circle" src={IconUser}/>
                  <Text fontSize="2xl">Unnamed</Text>
                  <div className="d-flex justify-content-center align-items-center">
                      <img src={IconETH}/>
                      <Text fontSize="2xl">{wallet_Address}</Text>
                  {/*</div>*/}
                  {/*<img className="mx-auto d-block" src={TitleMutant}/>*/}
                  {/*<div >*/}
                      <Text as='b' fontSize={{base: '10px', md: '10px', lg: '3xl'}} color='#DCB839'>{message}&nbsp;</Text>
                  </div>
              </div>
              <div>
                  <div className="text-center mx-5">
                      <Container centerContent>
                          <SimpleGrid className="mx-5" columns={{base: 1, md: 2, lg: 1}}>
                              {selectCard.map((val, i) => (
                                  <Box onClick={() => handleRemove(val)} w="90%">
                                      <CardWMutant dataImg={val} idx={i} isId={val.id == null}/>
                                  </Box>
                              ))}
                          </SimpleGrid>
                      </Container>
                      {/*<Button className={"btn-mint"} onClick={() => goMutant(selectCard)} disabled={true} color='white' backgroundColor="#7a995a">*/}
                      {/*    TAKE PILL*/}
                      {/*</Button>*/}
                    <Button className={"btn-mint"} onClick={() => goMutant(selectCard)} disabled={!isDisabled} color='white' backgroundColor="#7a995a">
                      TAKE PILL
                    </Button>
                  </div>
              </div>
              <div className="my-5 container">
                  <SimpleGrid columns={{base: 1, md: 4, lg: 4}}>
                      {list.map((val, i) => (
                          <Box onClick={() => handleClick(val)} w="70%">
                              <CardWMutant
                                  dataImg={val}
                                  idx={i}
                                  isBlur={isBlur(selectCard, val)}
                              />
                          </Box>
                      ))}
                  </SimpleGrid>
              </div>
              <Modal isOpen={isOpen} onClose={onClose} isCentered>
                  <ModalOverlay/>
                  <ModalContent bg="dark">
                      <ModalCloseButton
                          style={{color: "white", right: "-4%", border: "1px solid", borderRadius: "50%"}}/>
                      <ModalBody pb={6}>
                          <CardWMutant
                              dataImg={resultCard[0]}
                          />
                      </ModalBody>
                  </ModalContent>w
              </Modal>

              <CircleSpinnerOverlay
                  loading={isLoading}
                  color="yellow"
                  overlayColor="rgba(255, 210, 129, 0.3)"
              />
          </>
      );
  }

export default Mutant;
