import { Carousel, Container, Row, Col } from "react-bootstrap";
import Carousel1 from '../../assets/images/carousel1.png'
import Carousel2 from '../../assets/images/carousel2.png'

import leftIcon from '../../assets/images/arrow-left.png'
import rightIcon from '../../assets/images/arrow-right.png'
import Logo from '../../assets/images/logo.png'
import discordIcon from '../../assets/images/discord.png'
import openSeaIcon from '../../assets/images/Opensea.png'
import { Text } from "@chakra-ui/react";
import LogoNew from '../../assets/images/LogoNew.png'
import Title2 from "../../assets/images/title2.png";


function CarouselPage() {
    const nextIcon = <img src={rightIcon} />
    const prevIcon = <img src={leftIcon} />
  return (
    <Container>
      <Carousel indicators={false} controls={false}>
        <Carousel.Item>
            <Row>
                <Col className="text-center">
                    <div >
                        <Container >
                          <div className="d-flex justify-content-center align-items-center">
                            <Text className="font-v2" fontSize={{ base: '24px', md: '26px', lg: '5xl' }}>Worst Ape Vol.2</Text>
                            <img className='mt-2 mx-3 d-lg-none' src={Logo} style={{width: '45px', height: '45px'}} />
                            <img className='mt-4 mx-3 d-none d-lg-block' src={LogoNew} style={{width: '60px', height: '60px'}} />
                          </div>
                            <Text  color={'#FFDE59'} className="font-v2" fontSize={{ base: '16px', md: '26px', lg: '5xl' }}>Roadmap for Worst Ape Vol.2</Text>
                            <Text className="font-v2" fontSize={{ base: '14px', md: '26px', lg: '4xl' }}>- Launch of WA2 (all mints are out)</Text>
                          <Text className="font-v2" fontSize={{ base: '14px', md: '26px', lg: '4xl' }}>- Worst coin of all time</Text>
                          <Text className="font-v2" fontSize={{ base: '14px', md: '26px', lg: '4xl' }}>- A stake system</Text>
                          <Text className="font-v2" fontSize={{ base: '14px', md: '26px', lg: '4xl' }}>- Stake and earn rewards</Text>
                          <Text className="font-v2" fontSize={{ base: '14px', md: '26px', lg: '4xl' }}>- Exclusive Access</Text>
                          <div style={{marginTop: "80px"}}> <img  className="mx-auto d-block" src={Title2}/> </div>

                          {/*<div className="d-none d-lg-block">*/}
                            {/*    <div className="d-flex justify-content-start my-2 ">*/}
                            {/*        <a target='_blank' href="#" style={{width: '280px'}}>*/}
                            {/*        <button style={{height: '56px'}} className="btn btn-warning form-control text-white d-flex justify-content-center">*/}
                            {/*            <span className="mx-2 mt-2">Join Discord </span>*/}
                            {/*            <img className="mx-2 mt-2" src={discordIcon} />*/}
                            {/*        </button>*/}
                            {/*        </a>*/}
                            {/*        <a target='_blank' style={{width: '280px'}} href="https://opensea.io/collection/worst-ape">*/}
                            {/*        <button style={{height: '56px'}} className="btn btn-outline-warning form-control mx-2 text-white d-flex justify-content-center btn-opensea">*/}
                            {/*            <img className="mx-2 mt-2" src={openSeaIcon} />*/}
                            {/*            <span className="mx-2 mt-2">OpenSea</span>*/}
                            {/*        </button>*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="d-lg-none d-sm-block mb-3">*/}
                            {/*    <div className="d-flex justify-content-start my-2 ">*/}
                            {/*        <a target='_blank' href="#" >*/}
                            {/*        <button  className="btn btn-warning form-control text-white d-flex justify-content-center">*/}
                            {/*            <Text className="mt-1" fontSize={{ base: '15px', md: '26px', lg: '5xl' }}>Join Discord</Text>*/}
                            {/*            <img className="mx-2" src={discordIcon} />*/}
                            {/*        </button>*/}
                            {/*        </a>*/}
                            {/*        <a target='_blank' href="https://opensea.io/collection/worst-side?fbclid=IwAR1w2GJFDKio_qEOWIA8iBsD2z9WvwXIgpgYeb8ZvVhDF1ZeeWaUSwUZDTs">*/}
                            {/*        <button className="btn btn-outline-warning form-control mx-2 text-white d-flex justify-content-center btn-opensea">*/}
                            {/*            <img className="mx-2" src={openSeaIcon} />*/}
                            {/*            <span className="mx-2">OpenSea</span>*/}
                            {/*        </button>*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                        </Container>

                    </div>
                </Col>

            </Row>
        </Carousel.Item>
          {/*<Carousel.Item>*/}
          {/*    <Row>*/}
          {/*        <Col sm={12} md={6} lg={6} className="text-start">*/}
          {/*            <div >*/}
          {/*                <Container >*/}
          {/*                    <div className="d-flex">*/}
          {/*                        <Text className="my-3" fontSize={{ base: '24px', md: '26px', lg: '5xl' }}>Project of October </Text>*/}
          {/*                        <img className='mt-4 mx-3 d-none d-lg-block' src={Logo} style={{width: '60px', height: '60px'}}  />*/}
          {/*                        <img className='mt-2 mx-3 d-lg-none' src={Logo} style={{width: '45px', height: '45px'}}  />*/}
          {/*                    </div>*/}
          {/*                    <Text color={'#DCB839'} className="my-3" fontSize={{ base: '16px', md: '26px', lg: '5xl' }}>Worst Town by Worst Ape X Fire Gallery</Text>*/}
          {/*                    <Text fontSize={{ base: '14px', md: '26px', lg: 'lg' }}>What's "Worst Town"?*/}
          {/*                        Worst Ape and Fire Gallery worked together on the project known as Worst Town.*/}
          {/*                        Future of humanity and comic book themes are the concepts.*/}
          {/*                        In addition to being free mint NFTs, it was made to thank all of the worst supporters.*/}
          {/*                    </Text>*/}
          {/*                    <div className="d-none d-lg-block">*/}
          {/*                        <div className="d-flex justify-content-start my-2 ">*/}
          {/*                            <a target='_blank' href="#" style={{width: '280px'}}>*/}
          {/*                                <button style={{height: '56px'}} className="btn btn-warning form-control text-white d-flex justify-content-center">*/}
          {/*                                    <span className="mx-2 mt-2">Join Discord </span>*/}
          {/*                                    <img className="mx-2 mt-2" src={discordIcon} />*/}
          {/*                                </button>*/}
          {/*                            </a>*/}
          {/*                            <a target='_blank' style={{width: '280px'}} href="https://opensea.io/collection/worst-mutant-ape">*/}
          {/*                                <button style={{height: '56px'}} className="btn btn-outline-warning form-control mx-2 text-white d-flex justify-content-center btn-opensea">*/}
          {/*                                    <img className="mx-2 mt-2" src={openSeaIcon} />*/}
          {/*                                    <span className="mx-2 mt-2">OpenSea</span>*/}
          {/*                                </button>*/}
          {/*                            </a>*/}
          {/*                        </div>*/}
          {/*                    </div>*/}
          {/*                    <div className="d-lg-none d-sm-block mb-3">*/}
          {/*                        <div className="d-flex justify-content-start my-2 ">*/}
          {/*                            <a target='_blank' href="#" >*/}
          {/*                                <button  className="btn btn-warning form-control text-white d-flex justify-content-center">*/}
          {/*                                    <Text className="mt-1" fontSize={{ base: '15px', md: '26px', lg: '5xl' }}>Join Discord</Text>*/}
          {/*                                    <img className="mx-2" src={discordIcon} />*/}
          {/*                                </button>*/}
          {/*                            </a>*/}
          {/*                            <a target='_blank' href="https://opensea.io/collection/worst-side?fbclid=IwAR1w2GJFDKio_qEOWIA8iBsD2z9WvwXIgpgYeb8ZvVhDF1ZeeWaUSwUZDTs">*/}
          {/*                                <button className="btn btn-outline-warning form-control mx-2 text-white d-flex justify-content-center btn-opensea">*/}
          {/*                                    <img className="mx-2" src={openSeaIcon} />*/}
          {/*                                    <span className="mx-2">OpenSea</span>*/}
          {/*                                </button>*/}
          {/*                            </a>*/}
          {/*                        </div>*/}
          {/*                    </div>*/}

          {/*                </Container>*/}

          {/*            </div>*/}
          {/*        </Col>*/}
          {/*        <Col sm={12} md={6} lg={6}>*/}
          {/*            <img className="w-100 "*/}
          {/*                 src={Carousel1}*/}
          {/*                 alt="First slide"*/}
          {/*            />*/}
          {/*        </Col>*/}
          {/*    </Row>*/}
          {/*</Carousel.Item>*/}
      </Carousel>
    </Container>
  );
}

export default CarouselPage;
