import {BigNumber, ethers} from "ethers";
import AssetContractSharedNFT from "../AssetContractShared.json";

import MySwal from "sweetalert2";
import {connectAccount, contractV2, wallet_Address} from "../components/layout/navbar_head";
import axios from "axios";
import IconMutant from "../assets/images/mutant.png";


export const AssetContractSharedAddress = "0x495f947276749ce646f68ac8c248420045cb7b5e";
export const WorstApeContractV2 = "0x2d9D46b9b91CD01dD2F42c47Dbb0d96B3fC72499";

async function handleMint(mintAmount) {
            try {
                const mintprice = await contractV2.mutant();
                const cost = Number(mintprice.toString()) / (1000000000000000000);
                const response = await contractV2.mintLv3(BigNumber.from(mintAmount), {
                    value: ethers.utils.parseEther((cost * mintAmount).toString()),
                });
                console.log("response: ", response);
            } catch (err) {
                console.log("error1: ", err.reason);
                MySwal.fire({
                    title: <strong>Error</strong>,
                    html: err.reason,
                    icon: 'error',
                    type: "error",
                    iconColor: 'black',
                    confirmButtonColor: 'black'
                });
            }

    }
export async function eatPhilandApe(eat) {

    if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
            AssetContractSharedAddress,
            AssetContractSharedNFT.abi,
            signer
        );
        try {
            const response = await contract.safeTransferFrom(wallet_Address,'0xcbf06e7B58397113476fF1269025e26FABf76de3',eat,1,ethers.utils.hexZeroPad(ethers.utils.hexlify(1), 32));
            console.log("response: ", response);
             await contractV2.takePill();

          return true;
        } catch (err) {
            console.log("error1: ", err.reason);
            MySwal.fire({
                title: <strong>Error</strong>,
                html: err.reason,
                icon: 'error',
                type: "error",
                iconColor: 'black',
                confirmButtonColor: 'black'
            });
        }
    }
}

export async function getPhileandSide() {
  console.log("wallet_Address: ", wallet_Address);

if(wallet_Address=='0x'){
  await connectAccount();
}else {
  let NFT = [];
  const options = {
    method: 'GET',
    url: `https://deep-index.moralis.io/api/v2/${wallet_Address}/nft`,
    params: {
      chain: 'eth',
      format: 'decimal',
      token_addresses: ['0xf67b2a76fbfd13a7eddb665f8bf72483d09ed317', '0x495f947276749ce646f68ac8c248420045cb7b5e'],
    },
    headers: {
      accept: 'application/json',
      // 'X-API-Key': 's0iwyeW3C6QEFDIRBT5JuwSgL3x5jrbqHQlA9eWtRUaODHtdRU8vQKEkKosWAMTn'
      'X-API-Key':'UgMXcy3xF862ttZFgQ8n8fRlZxfShBeRT6i1SMKDY7aRAUBjihDZJCWl43vkL8c4'
    }
  };
  await axios
    .request(options)
    .then(async function (response) {
      response.data.result.map((nft) => {
        if (nft.token_address === "0xf67b2a76fbfd13a7eddb665f8bf72483d09ed317" || nft.token_id === "92244314075412587912220539187142986909104715834096045513284875096358379324322"
          || nft.token_id === "92244314075412587912220539187142986909104715834096045513284875095258867696116"
          || nft.token_id === "92244314075412587912220539187142986909104715834096045513284875093059844440564"
        ) {
          NFT.push(nft);
        }
      });


    })

    .catch(function (error) {
      console.error(error);
    });

  return NFT;
}
}
export async function getAPE() {
    // const wallet = account[0];
    let NFT = [];
    const options = {
        method: 'GET',
        url: `https://deep-index.moralis.io/api/v2/${wallet_Address}/nft`,
        // url: `https://deep-index.moralis.io/api/v2/0x48E7C48A7255ff3663B8A055415f9387d7d83cDB/nft`,
        params: {chain: 'eth', format: 'decimal',token_addresses:['0x495f947276749ce646f68ac8c248420045cb7b5e'],},
        headers: {accept: 'application/json', 'X-API-Key': 's0iwyeW3C6QEFDIRBT5JuwSgL3x5jrbqHQlA9eWtRUaODHtdRU8vQKEkKosWAMTn'}
    };
    await axios
        .request(options)
        .then(async function (response) {
            response.data.result.map(async (nft) => {
                if (nft.token_id === "92244314075412587912220539187142986909104715834096045513284875096358379324322"
                    ||  nft.token_id === "92244314075412587912220539187142986909104715834096045513284875095258867696116"
                    ||  nft.token_id === "92244314075412587912220539187142986909104715834096045513284875093059844440564"
                ) {

                }else{
                    if (nft.token_uri.includes("0xcbf06e7b58397113476ff1269025e26fabf76de") === true) {
                        NFT.push(nft);

                    }
                }
            });

        })
        .catch(function (error) {
            console.error(error);
        });
    console.log(NFT);
    return NFT;
}


