import { Box, Button, Grid, Text } from '@chakra-ui/react'
import { Card, Container } from 'react-bootstrap'
import IconWorstCity from '../../assets/images/icon-w-city.png'
import IconMutant from '../../assets/images/mutant.png'

import {BigNumber, ethers} from "ethers";
import WorstApeV2 from "../../WorstApeV2.json";
import axios from 'axios';
import MySwal from "sweetalert2";
import NavBar, {contractV2, wallet_Address,connectAccount} from "../layout/navbar_head";
import {useEffect, useState,useMemo} from "react";
import {useNavigate} from "react-router-dom";
import vol1 from '../../assets/images/1.png'
import vol2 from '../../assets/images/2.png'
import vol3 from '../../assets/images/3.png'
import vol4 from '../../assets/images/4.png'
import vol5 from '../../assets/images/5.png'
import vol6 from '../../assets/images/6.png'
import vol7 from '../../assets/images/7.png'
import vol8 from '../../assets/images/8.png'
import vol9 from '../../assets/images/9.png'
import vol10 from '../../assets/images/10.png'
import vol11 from '../../assets/images/11.png'
import vol12 from '../../assets/images/12.png'
import vol13 from '../../assets/images/13.png'
import vol14 from '../../assets/images/14.png'
import vol15 from '../../assets/images/15.png'
import vol16 from '../../assets/images/16.png'
import vol17 from '../../assets/images/17.png'
import vol18 from '../../assets/images/18.png'
import vol19 from '../../assets/images/19.png'
import vol20 from '../../assets/images/20.png'
import vol21 from '../../assets/images/21.png'
import vol22 from '../../assets/images/22.png'
import vol23 from '../../assets/images/23.png'
import vol24 from '../../assets/images/24.png'
import vol25 from '../../assets/images/25.png'
import vol26 from '../../assets/images/26.png'
import vol27 from '../../assets/images/27.png'
import vol28 from '../../assets/images/28.png'
import vol29 from '../../assets/images/29.png'
import vol30 from '../../assets/images/30.png'

function MintPage () {
  const navigate = useNavigate();
  const [imageArray, setImageArray] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);



  // const [selectCard, setSelectCard] = useState();
    //
    // const [list, setList] = useState([]);
    //
    // const [totalNFT, settotalNFT] = useState(0);
    //
    // const nft = useMemo(() => getNFT(), [totalNFT]);
    // const getNFT = async () => {
    //
    //     return 1;
    //
    // };
    const [message, setMessage] = useState('');
    const [isFree, setFree] = useState(true);

  async function goMutant() {

    if (contractV2) {
      ///  เปลี่ยน path
      navigate("/mutants");
      this.history.push('/mutants')
    } else {

      await MySwal.fire({
        html: 'Please Connet Wallet',
        icon: 'error',
        type: "error",
        iconColor: 'black',
        confirmButtonColor: 'black'
      });
    }
  }
    // todo: mintcount
    setInterval(async () => {
        const totalMint = await contractV2.totalSupply();
        setMessage(`Total Mint: ${totalMint}/3333`);
    }, 1000);

    //
    useEffect(() => {
        const interval = setInterval(() => {
            // fetchGraphData();
        },1000);
        return () => clearInterval(interval);
    }, []);
    // const fetchGraphData = async () => {
    //         const totalMint = await contractV2.totalSupply();
    //         setMessage(`${totalMint}/3333`);
    //         // setMessage(`4455`);
    //
    // };

  useEffect(() => {
    const interval = setInterval(() => {
      // สร้าง array ของรูปภาพที่ต้องการให้แสดง
      const newImageArray = [vol1,vol2,vol3,vol4,vol5,vol6,vol7,vol8,vol9,vol10,vol11,vol12,vol13,vol14,vol15,vol16,vol17,vol18,vol19,vol20,vol21,vol22,vol23,vol24,vol25,vol26,vol27,vol28,vol29,vol30];
      setImageArray(newImageArray);

      // เปลี่ยน index ของรูปภาพที่แสดง
      setCurrentImageIndex(prevIndex => {
        const newIndex = prevIndex + 1;
        return newIndex >= newImageArray.length ? 0 : newIndex;
      });
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);


    async function mintOne() {
        if (contractV2) {
            try {
                const mintPrice = await contractV2.cost();
                const cost = Number(mintPrice.toString()) / (1000000000000000000);
                const response = await contractV2.mint(BigNumber.from(1), {
                    value: ethers.utils.parseEther((cost).toString()),
                });
                console.log("response: ", response.data);
            } catch (err) {
                console.log('poy',err);
              await MySwal.fire({
                html: err.reason,
                icon: 'error',
                type: "error",
                iconColor: 'black',
                confirmButtonColor: 'black'
              });
            }
        } else {
            await connectAccount();
            try {
                const mintPrice = await contractV2.cost();
                const cost = Number(mintPrice.toString()) / (1000000000000000000);
                const response = await contractV2.mint(BigNumber.from(1), {
                    value: ethers.utils.parseEther((cost).toString()),
                });
                console.log("response: ", response.data);
            } catch (err) {
                console.log(err)
                console.log("error1: ", err.reason);
                await MySwal.fire({
                    html: err.reason,
                    icon: 'error',
                    type: "error",
                    iconColor: 'black',
                    confirmButtonColor: 'black'
                });
            }
    }
    }
    async function mintThree() {
        if (contractV2) {
            try {
                const mintPrice = await contractV2.cost();
                const cost = Number(mintPrice.toString()) / (1000000000000000000);
                const response = await contractV2.mint(BigNumber.from(3), {
                    value: ethers.utils.parseEther((cost*3).toString()),
                });
                console.log("response: ", response.data);
            } catch (err) {
              console.log('poy'+err)

              await MySwal.fire({
                html: err.reason,
                icon: 'error',
                type: "error",
                iconColor: 'black',
                confirmButtonColor: 'black'
              });
            }
        } else {

          await connectAccount();
            try {
                const mintPrice = await contractV2.cost();
                const cost = Number(mintPrice.toString()) / (1000000000000000000);
                const response = await contractV2.mint(BigNumber.from(3), {
                    value: ethers.utils.parseEther((cost*3).toString()),
                });
                console.log("response: ", response.data);
            } catch (err) {
              console.log('poy'+err)
              await MySwal.fire({
                html: err.reason,
                icon: 'error',
                type: "error",
                iconColor: 'black',
                confirmButtonColor: 'black'
              });
            }

        }
    }
    async function mintFive() {
        if (contractV2) {
            try {
                const mintPrice = await contractV2.cost();
                const cost = Number(mintPrice.toString()) / (1000000000000000000);
                const response = await contractV2.mint(BigNumber.from(5), {
                    value: ethers.utils.parseEther((cost*5).toString()),
                });
                console.log("response: ", response.data);
            } catch (err) {

              await MySwal.fire({
                html: err.reason,
                icon: 'error',
                type: "error",
                iconColor: 'black',
                confirmButtonColor: 'black'
              });
            }
        } else {
            await connectAccount();
            try {
                const mintPrice = await contractV2.cost();
                const cost = Number(mintPrice.toString()) / (1000000000000000000);
                const response = await contractV2.mint(BigNumber.from(5), {
                    value: ethers.utils.parseEther((cost*5).toString()),
                });
                console.log("response: ", response.data);
            } catch (err) {

              await MySwal.fire({
                html: err.reason,
                icon: 'error',
                type: "error",
                iconColor: 'black',
                confirmButtonColor: 'black'
              });
            }

        }
    }
    async function mintSevent(){
        if (contractV2) {
            try {
                const mintPrice = await contractV2.cost();
                const cost = Number(mintPrice.toString()) / (1000000000000000000);
                const response = await contractV2.mint(BigNumber.from(7), {
                    value: ethers.utils.parseEther((cost*7).toString()),
                });
                console.log("response: ", response.data);
            } catch (err) {
                console.log(err)
                console.log("error1: ", err.reason);
                await MySwal.fire({
                    html: err.reason,
                    icon: 'error',
                    type: "error",
                    iconColor: 'black',
                    confirmButtonColor: 'black'
                });
            }
        } else {
            await connectAccount();
            try {
                const mintPrice = await contractV2.cost();
                const cost = Number(mintPrice.toString()) / (1000000000000000000);
                const response = await contractV2.mint(BigNumber.from(7), {
                    value: ethers.utils.parseEther((cost*7).toString()),
                });
                console.log("response: ", response.data);
            } catch (err) {
                console.log(err)
                console.log("error1: ", err.reason);
                await MySwal.fire({
                    html: err.reason,
                    icon: 'error',
                    type: "error",
                    iconColor: 'black',
                    confirmButtonColor: 'black'
                });
            }

        }

    }
    async function mintNine(){
        if (contractV2) {
            try {
                const mintPrice = await contractV2.cost();
                const cost = Number(mintPrice.toString()) / (1000000000000000000);
                const response = await contractV2.mint(BigNumber.from(9), {
                    value: ethers.utils.parseEther((cost*9).toString()),
                });
                console.log("response: ", response.data);
            } catch (err) {
                console.log(err)
                console.log("error1: ", err.reason);
                await MySwal.fire({
                    html: err.reason,
                    icon: 'error',
                    type: "error",
                    iconColor: 'black',
                    confirmButtonColor: 'black'
                });
            }
        } else {
            await connectAccount();
            try {
                const mintPrice = await contractV2.cost();
                const cost = Number(mintPrice.toString()) / (1000000000000000000);
                const response = await contractV2.mint(BigNumber.from(9), {
                    value: ethers.utils.parseEther((cost*9).toString()),
                });
                console.log("response: ", response.data);
            } catch (err) {
                console.log(err)
                console.log("error1: ", err.reason);
                await MySwal.fire({
                    html: err.reason,
                    icon: 'error',
                    type: "error",
                    iconColor: 'black',
                    confirmButtonColor: 'black'
                });
            }

        }
    }



    return(
        <>

                <div>
                    <Text className={"font-v2"} as='b' fontSize={{base: '10px', md: '10px', lg: '3xl'}} color='#FFDE59'>{message}&nbsp;</Text>
                </div>
          <div>
            <img style={{ margin: "0 35%" }} src={imageArray[currentImageIndex]} width="30%" />
          </div>
                <div style={{margin: '30px'}} >
                    <Button className={"btn-mint"} colorScheme='#7a995a' color='white' onClick={mintOne}
                            style={{margin: '10px'}}>
                        <Text fontSize='32px'>1 MINT</Text>
                    </Button>
                  <Button className={"btn-mint"} colorScheme='#7a995a' color='white' onClick={mintThree}
                          style={{margin: '10px'}}>
                    <Text fontSize='32px'>3 MINT</Text>
                    </Button>
                  <Button className={"btn-mint"} colorScheme='#7a995a' color='white' onClick={mintFive}
                          style={{margin: '10px'}}>
                    <Text fontSize='32px'>5 MINT</Text>
                    </Button>
                 <div><Text className={"font-v2"}>#### OR ####</Text></div>
                  <Button className={"btn-mint"} colorScheme='#7a995a' color='white' onClick={goMutant}
                         style={{margin: '10px'}}>
                  <Text fontSize='32px'>TAKE PILL</Text >
                 </Button>
                </div>


        {/*<div className='container d-none d-lg-block' style={{marginTop: '64px'}}>*/}
        {/*    <Grid templateColumns='repeat(3, 1fr)' gap={6}>*/}

        {/*        <Card className='px-4 py-5 border-mint-card' style={{backgroundColor:'#1D1D1D', border: '1px solid #DCB839'}}>*/}
        {/*            <Card.Title className='d-flex align-middle my-1'>*/}
        {/*                <img className='mt-2 mx-2' width='40px' style={{height: '40px'}} src={IconMutant} />*/}
        {/*                <Text fontSize='5xl'>Free Mint</Text>*/}
        {/*            </Card.Title>*/}
        {/*            <Card.Body className='text-start'>*/}
        {/*                <Text fontSize='2xl' >RELEASE 300 TOKENS</Text>*/}
        {/*                /!*<Text color='#DCB839' fontSize='2xl'>0.007 ETH</Text>*!/*/}
        {/*                /!*<Text color='#1D1D1D' fontSize='2xl'>s</Text> <pre/>*!/*/}
        {/*            </Card.Body>*/}
        {/*        </Card>*/}


        {/*        <Card className='px-4 py-5 border-mint-card' style={{backgroundColor:'#1D1D1D', border: '1px solid #DCB839'}}>*/}
        {/*            <Card.Title className='d-flex align-middle my-1'>*/}
        {/*                <img className='mt-2 mx-2' width='40px' style={{height: '40px'}} src={IconMutant} />*/}
        {/*                <Text fontSize='5xl'>Mint</Text>*/}
        {/*            </Card.Title>*/}
        {/*            <Card.Body className='text-start'>*/}
        {/*                <Text fontSize='2xl' >RELEASE 4135 TOKENS</Text>*/}
        {/*                /!*<Text color='#DCB839' fontSize='2xl'>0.007 ETH</Text>*!/*/}
        {/*                /!*<Text color='#1D1D1D' fontSize='2xl'>s</Text> <pre/>*!/*/}
        {/*            </Card.Body>*/}
        {/*        </Card>*/}

        {/*        <Card className='px-4 py-5 border-mint-card' style={{backgroundColor:'#1D1D1D', border: '1px solid #DCB839'}}>*/}
        {/*        <Card.Title className='d-flex align-middle'>*/}
        {/*                <img className='mt-2 mx-2' width='40px' style={{height: '40px'}} src={IconMutant} />*/}
        {/*                <Text fontSize='5xl' color='#DCB839'>Mutant</Text>*/}
        {/*            </Card.Title>*/}
        {/*            <Card.Body className='text-start'>*/}
        {/*                <Text fontSize='2xl'>RELEASE 1100 TOKENS</Text> <pre/>*/}
        {/*                <Text color='#DCB839' fontSize='2xl'>FREE</Text>*/}

        {/*            </Card.Body>*/}
        {/*        </Card>*/}



        {/*    </Grid>*/}


        {/*</div>*/}
        {/*<div className='d-lg-none d-sm-block container' style={{marginTop: '64px'}}>*/}

        {/*    <Card className='px-4 py-5 border-mint-card' style={{backgroundColor:'#1D1D1D', border: '1px solid #DCB839'}}>*/}
        {/*        <Card.Title className='d-flex align-middle my-1'>*/}
        {/*            <img className='mt-2 mx-2' width='40px' style={{height: '40px'}} src={IconMutant} />*/}
        {/*            <Text fontSize='5xl'>Mint</Text>*/}
        {/*        </Card.Title>*/}
        {/*        <Card.Body className='text-start'>*/}
        {/*            <Text fontSize='2xl' >RELEASE 4135 TOKENS</Text>*/}
        {/*            /!*<Text color='#DCB839' fontSize='2xl'>0.007 ETH</Text>*!/*/}
        {/*            /!*<Text color='#1D1D1D' fontSize='2xl'>s</Text> <pre/>*!/*/}
        {/*        </Card.Body>*/}
        {/*    </Card>*/}
        {/*</div>*/}
        </>

    )
}

export default MintPage;
