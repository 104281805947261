import {Row, Col, Card, ProgressBar} from 'react-bootstrap'
import Logo from '../../assets/images/logo.png'
import Person1 from '../../assets/images/person1.png'
import Person2 from '../../assets/images/person2.png'
import Pic1 from "../../assets/images/pic1.png";
import Pic2 from "../../assets/images/pic2.png";
import WorstApe from "../../assets/images/WorstApe.png";
import WorstCity from "../../assets/images/WorstCity.png";
import WorstPill from "../../assets/images/WorstPill.png";
import WorstSide from "../../assets/images/WorstSide2.png";
import PicPro2 from "../../assets/images/project2.png";
import PicPro3 from "../../assets/images/project3.png";
import PicPro4 from "../../assets/images/project4.png";

import {Text } from "@chakra-ui/react";

function OurProjectPage () {
    return (
        <div className='container'>
                <div className='d-flex justify-content-center my-3'>
                    <Text className={"font-v2"} fontSize={{ base: '49px', md: '72px', lg: '6xl' }}>Our Projects</Text>
                </div>
                <div className='d-flex justify-content-center'>
                    <div className='mx-2'>
                        <Card bg="dark" text='white' style={{marginRight: '1%'}}>
                            <Card.Img variant="top" src={WorstApe} />
                            <Card.Body>
                                <Card.Img  variant="top" src={Pic1} style={{padding: '30%', position: 'absolute', top: '37%', left:0}} />
                                <Card.Text className="mt-4 mb-4">
                                    <Text fontSize={{ base: '14px', md: '1xl', lg: '2xl' }}>Worst Ape</Text>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        {/*<ProgressBar style={{height: '2%', background: 'rgba(0, 0, 0, 0.1)' }} className='mt-2' variant="warning" now={100} />*/}
                        {/*<div className='text-end'>100% completed</div>*/}
                    </div>
                    <div className='mx-2'>
                        <Card bg="dark" text='white' style={{marginRight: '1%'}}>
                            <Card.Img variant="top" src={WorstPill} />
                            <Card.Body>
                                <Card.Img  variant="top" src={PicPro2} style={{padding: '30%', position: 'absolute', top: '37%', left:0}} />
                                <Card.Text className="mt-4 mb-4">
                                    <Text fontSize={{ base: '14px', md: '1xl', lg: '2xl' }}>Worst Pill</Text>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    {/*    <ProgressBar style={{height: '2%', background: 'rgba(0, 0, 0, 0.1)' }} className='mt-2' variant="warning" now={100} />*/}
                    {/*<div className='text-end'>100% completed</div>*/}
                    </div>
                    {/*<div className='mx-2'>*/}
                    {/*    <Card bg="dark" text='white' style={{marginRight: '1%'}}>*/}
                    {/*        <Card.Img variant="top" src={WorstSide}  />*/}
                    {/*        <Card.Body>*/}
                    {/*            <Card.Img  variant="top" src={PicPro3} style={{padding: '30%', position: 'absolute', top: '37%', left:0}} />*/}
                    {/*            <Card.Text className="mt-4 mb-4">*/}
                    {/*                <Text fontSize={{ base: '14px', md: '1xl', lg: '2xl' }}>Worst Side</Text>*/}
                    {/*            </Card.Text>*/}
                    {/*        </Card.Body>*/}
                    {/*    </Card>*/}
                    {/*/!*    <ProgressBar style={{height: '2%', background: 'rgba(0, 0, 0, 0.1)' }} className='mt-2' variant="warning" now={100} />*!/*/}
                    {/*/!*<div className='text-end'>100% completed</div>*!/*/}
                    {/*</div>*/}
                </div>
                {/*<div style={{marginTop: '80px'}}>*/}
                {/*    <div className='d-flex justify-content-center my-3'>*/}
                {/*    </div>*/}
                {/*    <div className='d-flex justify-content-center'>*/}
                {/*        <div className='mx-2'>*/}
                {/*        <Card bg="dark" text='white' style={{marginRight: '1%'}}>*/}
                {/*                <Card.Img variant="top" src={WorstApe} width='100%' />*/}
                {/*                <Card.Body>*/}
                {/*                    <Card.Img  variant="top" src={PicPro3} style={{padding: '30%', position: 'absolute', top: '37%', left:0}} />*/}
                {/*                    <Card.Text className="mt-5 mb-4">*/}
                {/*                        <Text fontSize='2xl'>Mutant Ape</Text>*/}
                {/*                    </Card.Text>*/}
                {/*                </Card.Body>*/}
                {/*            </Card>*/}
                {/*            /!*<ProgressBar style={{height: '2%', background: 'rgba(0, 0, 0, 0.1)' }} className='mt-2' variant="warning" now={100} />*!/*/}
                {/*            /!*<div className='text-end'>100% completed</div>*!/*/}
                {/*        </div>*/}
                {/*        <div className='mx-2'>*/}
                {/*            <Card bg="dark" text='white' style={{marginRight: '1%'}}>*/}
                {/*                <Card.Img variant="top" src={WorstCity} width='100%' />*/}
                {/*                <Card.Body>*/}
                {/*                    <Card.Img  variant="top" src={PicPro4} style={{padding: '30%', position: 'absolute', top: '37%', left:0}} />*/}
                {/*                    <Card.Text className="mt-5 mb-4">*/}
                {/*                        <Text fontSize='2xl'>Worst City</Text>*/}
                {/*                    </Card.Text>*/}
                {/*                </Card.Body>*/}
                {/*            </Card>*/}
                {/*            /!*<ProgressBar style={{height: '2%', background: 'rgba(0, 0, 0, 0.1)' }} className='mt-2' variant="warning" now={100} />*!/*/}
                {/*            /!*<div className='text-end'>100% completed</div>*!/*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className='d-flex justify-content-center my-5'>*/}
                {/*    <div className='text-start mx-5 project-coming'>*/}
                {/*        <ul className='mx-3'>*/}
                {/*            <li><Text fontSize={{ base: '10px', md: '28px', lg: '21px' }}>Mutation between 3 projects</Text></li>*/}
                {/*            /!*<li><Text fontSize={{ base: '10px', md: '28px', lg: '21px' }}>Worst Ape</Text></li>*!/*/}
                {/*            /!*<li><Text fontSize={{ base: '10px', md: '28px', lg: '21px' }}>Worst Pill</Text></li>*!/*/}
                {/*            /!*<li><Text fontSize={{ base: '10px', md: '28px', lg: '21px' }}>Worst Side </Text></li>*!/*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className='text-start mx-4 project-coming'>*/}
                {/*        <ul className='mx-2'>*/}
                {/*            /!*<li><Text fontSize={{ base: '10px', md: '28px', lg: '21px' }}>Collaboration with Fire Gallery</Text></li>*!/*/}
                {/*            <li><Text fontSize={{ base: '10px', md: '28px', lg: '21px' }}>Comic book Style</Text></li>*/}
                {/*            <li><Text fontSize={{ base: '10px', md: '28px', lg: '21px' }}>Free mint</Text></li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</div>*/}
        </div>

    );
}

export default OurProjectPage;
